
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { combineLatest, of } from 'rxjs';
import { GetTaxonomiesAction, Taxonomy, getTaxonomies, getTaxonomiesLoaded } from '../../store';
import { cloneDeep, cloneDeepWith, flatten, get, isArray, isEmpty, keyBy } from 'lodash-es';
import { PassthroughCacheFactoryService } from '../cache/api-cache-factory.service';
import { AppState } from '../../store/app-reducer';
import { Store } from '@ngrx/store';

const debugTaxonomyData = {};

@Injectable()
export class TaxonomiesService {

  /**
   * This cache is used instead of simple get request, it will first try to
   * get the requested URL from ongoing requests buffer, then from cache,
   * and finally from the API by using the passed apiClient
   */
  passthroughCache = this.passthroughCacheFactory.createCache({
    domain: 'taxonomies',
    apiClient: this.rest,
    ttl: 60 * 1000
  });

  constructor(
    private rest: RestService,
    private passthroughCacheFactory: PassthroughCacheFactoryService,
    private store: Store<AppState>,
  ) { }

  getAllTaxonomyData() {
    return this.passthroughCache.getData('taxonomies')
      .pipe(
        map(({ data }: any) => {
          const taxonomies = data.map(tax => ({ ...tax, sectionPage: tax.sectionPage }))
          return taxonomies;
        })
      );
  }

  /**
   * This method fetches all taxonomies with basic data, page by page
   * @returns array of all taxonomies with basic projection data
   */
  getTaxonomies() {
    const pageSize = 200;
    return this.passthroughCache.getData(`taxonomies/basic-projection?size=${pageSize}&page=0`)
      .pipe(
        mergeMap(({data, meta}) => {
          const taxonomiesFirstPage = data.map(this.mapTaxonomyProps);
          // if we have less than max page size of taxonomies, return data results
          const totalTaxonomies = meta.page.total;
          if(pageSize >= totalTaxonomies) {
            return of(taxonomiesFirstPage);
          }

          // otherwise calculate how many pages we have and fetch and merge multiple page results
          // note that we're not fetching the first page, we already have it
          const numberOfPages = Math.ceil(+meta.page.total / 200);
          const pageRequestPaths = Array(numberOfPages - 1).fill(0)
            .map((p, i) => `taxonomies/basic-projection?size=${pageSize}&page=${i + 1}`);
          const pageRequests =  pageRequestPaths
            .map(path => this.passthroughCache.getData(path).pipe(map(res => res.data)));

          return combineLatest(pageRequests).pipe(
            map(taxonomyPages => {
              // add the first page we fetched to the begining of the array of pages data, and flatten it
              const allTaxonomies = flatten([taxonomiesFirstPage, ...taxonomyPages]).map(this.mapTaxonomyProps);
              return allTaxonomies;
            })
          );
        })
      );
  }

  /**
   *
   * For legacy code reasons add mapping for changed section flag name
   * and parentId -> parent mapping
   * TODO remove this once the model usage is updated to not rely on old prop names
   */
  mapTaxonomyProps(taxonomy) {
    return {
      ...taxonomy,
      sectionPage: taxonomy.sectionPage,
      parent: taxonomy.parentId
    }
  }

  getTaxonomy(id) {
    return this.passthroughCache.getData(`taxonomies/${id}`)
      .pipe(tap((taxonomiesData: any) => {
        // save a copy for debugging
        debugTaxonomyData[taxonomiesData.data?.id] = cloneDeep(taxonomiesData.data);
      }))
      .pipe(map((taxonomiesData: any) => taxonomiesData.data));
  }

  deleteTaxonomy(payload) {
    return this.rest.delete('taxonomies/' + payload.id).pipe(
      tap(() => this.passthroughCache.clear()),
      map((taxonomyData: any) => {
        return taxonomyData.data;
      }));
  }

  deleteTaxonomyLocalization({ taxonomyId, localizationId }) {
    return this.rest.delete(`taxonomies/${taxonomyId}/localized-versions/${localizationId}`).pipe(tap(() => this.passthroughCache.clear()));
  }

  createTaxonomy(payload) {
    return this.rest.post('taxonomies/', payload).pipe(
      tap(() => this.passthroughCache.clear()),
      map((taxonomyData: any) => {
        return taxonomyData.data;
      }));
  }

  updateTaxonomy(payload) {
    // debug code, check if the taxonomy coming into service lost data compared to when it was fetched form service
    detectCustomDataLossForTaxonomyAndLocalizations(debugTaxonomyData[payload.id], payload);

    const tax = { ...payload, parentId: payload.parent, sectionPage: payload.sectionPage }
    return this.rest.put('taxonomies/' + tax.id, tax).pipe(
      tap(() => this.passthroughCache.clear()),
      map((taxonomyData: any) => {
        // debug code, trigger if API loses the custom data post update
        detectCustomDataLossForTaxonomyAndLocalizations(payload, taxonomyData?.data, true);
        return taxonomyData.data;
      }));
  }

  getFilteredTaxonomies(name) {
    let requestPath = `taxonomies`;
    requestPath += name ? `?name=${name}` : '';
    return this.rest
      .get(requestPath).pipe(
      map((taxonomiesData: any) => taxonomiesData.data));
  }

  getTaxonomiesCustomData({name = '', ids = []}) {
    let requestPath = 'taxonomies';
    requestPath += name ? `?name=${name}` : '';
    if (ids && ids.length !== 0) {
      requestPath += name ? `&ids=${ids.join(',')}` : `?ids=${ids.join(',')}`;
    }

    return this.passthroughCache.getData(requestPath)
      .pipe(map((taxonomiesData: any) => taxonomiesData.data));
  }

  // get all taxonomies if they don't exist in store
  loadAllTaxonomies() {
    return combineLatest([
      this.store.select(getTaxonomies),
      this.store.select(getTaxonomiesLoaded),
    ]).pipe(
      map(([allTaxonomies, taxonomiesLoaded]: any[]) => [
        allTaxonomies,
        Object.keys(allTaxonomies).length,
        taxonomiesLoaded,
      ]),
      // get the taxonomies for reference if they haven't already been loaded
      tap(([, allTaxonomiesCount, taxonomiesLoaded]) => {
        if (allTaxonomiesCount === 0 && !taxonomiesLoaded) {
          // TODO do debounce to avoid api bashing
          this.store.dispatch(new GetTaxonomiesAction());
        }
      }),
      // only notify the subscriber if all taxonomies are fetched/exist in store
      filter(([, allTaxonomiesCount]) => allTaxonomiesCount > 0),
      take(1),
      map(([allTaxonomies]) => allTaxonomies)
    );
  }
}

export function setTaxonomiesOrder(taxonomies, allTaxonomies, primaryTaxonomyId, showTaxonomyPath = true, contentLocaleId = null, order = null) {
  /**
   * Order rules:
   * sort by order list
   * primary taxonomy on the top of the list
   * section taxonomies before the non-section taxonomies
   * levels of nodes in descending order
   * nodes in the same rank ordered alphabetically
   */
  const sortedTaxonomies = processTaxonomies(taxonomies, allTaxonomies, showTaxonomyPath, primaryTaxonomyId, false, contentLocaleId);

  if (order && order.length > 1) {
    sortedTaxonomies.sort((a, b) => sortById(a, b, order));
  } else {
    sortedTaxonomies.sort((a, b) => a.level - b.level);
    sortedTaxonomies.sort((a, b) => sortByHierarchy(a, b));
    sortedTaxonomies.sort((a, b) => sortByFlag(a, b, 'sectionPage'));
    sortedTaxonomies.sort((a, b) => sortByFlag(a, b, 'isPrimaryTaxonomy'));
  }
  return sortedTaxonomies;
}

export function getTaxonomyHierarchy(taxId, allTaxonomies, hierarchy = [], contentLocaleId = null) {
  const taxonomy = allTaxonomies[taxId];
  const name = get(taxonomy, `localizedDisplayData.${contentLocaleId}.name`, null) || taxonomy?.name;
  hierarchy.push(name);
  if (!taxonomy?.parent) {
    return hierarchy.reverse();
  }
  return getTaxonomyHierarchy(taxonomy.parent, allTaxonomies, hierarchy, contentLocaleId);
}

function sortByHierarchy(a, b) {
  const aHierarchy = a.hierarchy.join('').toLocaleLowerCase();
  const bHierarchy = b.hierarchy.join('').toLocaleLowerCase();
  if (aHierarchy < bHierarchy) { return -1; }
  if (aHierarchy > bHierarchy) { return 1; }
  return 0;
}

const sortById = (a, b, order) => {
  const indexA = order.indexOf(a.id);
  const indexB = order.indexOf(b.id);

  if (indexA === -1 && indexB === -1) {
    // If both IDs are not in the order array, keep their relative order
    return 0;
  } else if (indexA === -1) {
    // If only A's ID is not in the order array, move A to the end
    return 1;
  } else if (indexB === -1) {
    // If only B's ID is not in the order array, move B to the end
    return -1;
  } else {
    // Otherwise, compare their positions in the order array
    return indexA - indexB;
  }
}

function sortByFlag(a, b, flag) {
  const x = a[flag] ? 1 : 0;
  const y = b[flag] ? 1 : 0;
  return y - x;
}

export function getTaxonomyDisplayData(hierarchy, showTaxonomyPath = true) {
  const mainTaxonomy = hierarchy[hierarchy.length - 1];
  if (!showTaxonomyPath) {
    return [mainTaxonomy];
  }
  if (hierarchy.length < 3) {
    return hierarchy;
  }
  const rootTaxonomy = hierarchy[0];
  const parentTaxonomy = hierarchy[hierarchy.length - 2];
  const result = [rootTaxonomy, parentTaxonomy, mainTaxonomy];
  if (hierarchy.length === 3) {
    return result;
  }
  // a helper element that will be replaced with ellipsis on UI
  result.splice(1, 0, 'NODE_PLACEHOLDER');
  return result;
}

/**
 * This function produces a processed list of taxonomies that is better suited for display purposes
 *
 * @param selectedTaxonomies list of selected taxonomy entities
 * @param allTaxonomies map of all taxonomy entities, mapped by id
 * @param showTaxonomyPath flag determining if the full taxonomy path should be calculated
 * @param primaryTaxonomyId used to set the isPrimaryTaxonomy flag in return result list
 * @param showOnlyPermitted used in conjunction with taxonomy permissions
 * @param contentLocaleId id of the selected content locale
 * @returns list of selected taxonomies extended by additional properties used for display purposes
 */
export function processTaxonomies(
  selectedTaxonomies,
  allTaxonomies,
  showTaxonomyPath,
  primaryTaxonomyId = null,
  showOnlyPermitted = false,
  contentLocaleId = null,
) {
  const allTaxonomiesNotLoaded = Object.keys(allTaxonomies).length === 0;
  if (selectedTaxonomies.length === 0 || allTaxonomiesNotLoaded) {
    return [];
  }
  const processedTaxonomies = selectedTaxonomies
    .filter((taxo) => !!taxo)
    .filter((taxo) => {
      const taxoFromStore: Taxonomy = allTaxonomies[taxo.id];
      return taxoFromStore?.userHasPermission || showOnlyPermitted === false;
    })
    .map((taxo) => {
      const hierarchy = getTaxonomyHierarchy(taxo.id, allTaxonomies, [], contentLocaleId);
      return {
        ...taxo,
        isPrimaryTaxonomy: !!primaryTaxonomyId && taxo.id === primaryTaxonomyId,
        hierarchy,
        fullPath: hierarchy.join(' > '),
        taxonomyDisplayData: getTaxonomyDisplayData(hierarchy, showTaxonomyPath),
        level: hierarchy.length - 1,
        localizedDisplayData: allTaxonomies[taxo.id]?.localizedDisplayData
      };
    });

  return processedTaxonomies;
}

export function isTaxonomyAllowed(taxonomy, allTaxonomies, allowedTaxonomies = [], allowedTaxonomySubtrees = []) {
  const allowedAll = !allowedTaxonomies.length && !allowedTaxonomySubtrees.length;
  return allowedAll
    || allowedTaxonomies.includes(taxonomy.id)
    || allowedTaxonomySubtrees.some(id => taxonomy.id !== id && taxonomy.lookupId.startsWith(allTaxonomies[id].lookupId));
}


/* START DEBUG code */
function detectCustomDataLossForTaxonomyAndLocalizations(oldTaxonomy, newTaxonomy, postUpdate = false) {
  try {
    detectCustomDataLoss(
      get(oldTaxonomy, 'additionalItems.customTaxonomyConfiguration.metaData', null),
      get(newTaxonomy, 'customTaxonomyConfiguration.metaData', null)
        || get(newTaxonomy, 'additionalItems.customTaxonomyConfiguration.metaData', null),
      `Potential custom data loss in taxonomy service!` + (postUpdate ? ' API side!' : '')
    );

    if(!newTaxonomy.localizedVersions || !oldTaxonomy.localizedVersions) {
      return;
    }
    const newLocalizedVersions = keyBy(newTaxonomy.localizedVersions, 'id');

    oldTaxonomy.localizedVersions.forEach(oldLocalizedVersion => {
      const newLocalizedVersion = newLocalizedVersions[oldLocalizedVersion.id];
      if(!newLocalizedVersion) {
        return;
      }
      detectCustomDataLoss(
        get(oldLocalizedVersion, 'additionalItems.customTaxonomyConfiguration.metaData', null),
        get(newLocalizedVersion, 'additionalItems.customTaxonomyConfiguration.metaData', null),
        `Potential custom data loss in taxonomy service! Localized Taxonomy version!` + (postUpdate ? ' API side!' : '')
      );
    })

  } catch (error) {
    //
  }
}

/**
 * The main purpose of this function is to trigger a log error if we detect suspected
 * custom data loss scenario. we pass in custom data from before and after update, count
 * @param customDataOld
 * @param customDataNew
 */
export function detectCustomDataLoss(customDataOld, customDataNew, message = null) {
  try {
    message = message || `Taxonomy custom data had multiple field values removed! Potential custom data loss!`;
    // here we count how much custom data we had on init and at update time
    // if more than `fieldsRemovedThreshold` of fields become falsy or empty arrays
    // we will treat that as potential custom data loss scenario and log an error using NR
    const count01 = countTruthyProps(customDataOld)
    const count02 = countTruthyProps(customDataNew);
    const fieldsRemoved = count01 -  count02;
    // if more than this number of taxonomy custom data fields become falsy, trigger an alarm
    const fieldsRemovedThreshold = 5;
    if(fieldsRemoved >= fieldsRemovedThreshold) {
      console.log(`Custom data lost ${fieldsRemoved} values`);
      const error = new Error(message);
      console.error(error);
      // if we have New Relic enabled we need to retransmit these errors so we see them in NR
      if(window.NREUM && window.NREUM.noticeError) {
        window.NREUM.noticeError(error);
      }
    }
  } catch (error) {
    //
  }
}


// Taxonomy custom data loss debug code
function countTruthyProps(object) {
  let propCount = 0;

  cloneDeepWith(object, (value, key) => {
  // skip counting metadata
  if(key === '__cfgId' || key === '__sysDataSource') {
    return null;
  }

  if(isArray(value)) {
    if(!isEmpty(value)) {
      propCount++;
    }
    return null;
  }

  // value was reset
  if(!value) {
    return;
  }
  propCount++;
  });
  return propCount;
}
/* END DEBUG code */
